var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form } },
        [
          _c("h2", [_vm._v("商城状态:")]),
          _c("el-divider"),
          _vm.form.mall_falg == 1 && _vm.form.mall_status == 0
            ? _c("el-form-item", { attrs: { label: "", prop: "" } }, [
                _c(
                  "label",
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-value": "1",
                        "inactive-value": "0",
                        "active-color": "#13ce66",
                        "inactive-color": "#ccc",
                      },
                      on: { change: _vm.mallChange1 },
                      model: {
                        value: _vm.form.mall_falg,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "mall_falg", $$v)
                        },
                        expression: "form.mall_falg",
                      },
                    }),
                    _vm._v(
                      " 网销功能已开启，终端家数上线" +
                        _vm._s(_vm.form.mall_limit) +
                        "家 "
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.form.mall_falg == 1 && _vm.form.mall_status == 1
            ? _c("el-form-item", { attrs: { label: "", prop: "" } }, [
                _c(
                  "label",
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-value": "0",
                        "inactive-value": "1",
                        "active-color": "#13ce66",
                        "inactive-color": "#ccc",
                      },
                      on: { change: _vm.mallChange },
                      model: {
                        value: _vm.form.mall_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "mall_status", $$v)
                        },
                        expression: "form.mall_status",
                      },
                    }),
                    _vm._v(" 已暂停使用 客户提示语: "),
                    _c("el-input", {
                      staticStyle: { width: "220px" },
                      on: { blur: _vm.msgChange },
                      model: {
                        value: _vm.form.mall_msg,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "mall_msg", $$v)
                        },
                        expression: "form.mall_msg",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.form.mall_falg == 0
            ? _c("el-form-item", { attrs: { label: "", prop: "" } }, [
                _c(
                  "label",
                  [
                    _c("el-switch", {
                      attrs: {
                        disabled: "",
                        "active-value": "1",
                        "inactive-value": "0",
                        "active-color": "#13ce66",
                        "inactive-color": "#ccc",
                      },
                      model: {
                        value: _vm.form.mall_falg,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "mall_falg", $$v)
                        },
                        expression: "form.mall_falg",
                      },
                    }),
                    _vm._v(" 网销功能未开启，请联系商单工作人员 "),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("h2", [_vm._v("出货仓库:")]),
          _c("el-divider"),
          _c(
            "el-form-item",
            { attrs: { label: "出货仓库:", prop: "depot_id" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.depot_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "depot_id", $$v)
                    },
                    expression: "form.depot_id",
                  },
                },
                _vm._l(_vm.warehouse, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.depot_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "", prop: "" } }, [
            _c(
              "label",
              [
                _c("el-switch", {
                  attrs: {
                    "active-value": 1,
                    "inactive-value": 0,
                    "active-color": "#13ce66",
                    "inactive-color": "#ccc",
                  },
                  model: {
                    value: _vm.form.is_stock,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_stock", $$v)
                    },
                    expression: "form.is_stock",
                  },
                }),
                _vm._v(" 网销下单仅可选购有库存的品牌及商品 "),
              ],
              1
            ),
          ]),
          _c("h2", [_vm._v("联系方式:")]),
          _c("el-divider"),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.form.connect_type,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "connect_type", $$v)
                },
                expression: "form.connect_type",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "" } },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("业务员:客户将联系邀请其开通网销的业务员"),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "tel" } },
                [
                  _c(
                    "el-radio",
                    { attrs: { label: "2" } },
                    [
                      _vm._v(" 客服热线: "),
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: {
                          disabled: _vm.form.connect_type != 2,
                          placeholder: "请输入客服热线",
                          onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
                        },
                        model: {
                          value: _vm.form.tel,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "tel", $$v)
                          },
                          expression: "form.tel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("h2", [_vm._v("退货设置:")]),
          _c("el-divider"),
          _c("div", [
            _c(
              "label",
              [
                _c("el-switch", {
                  attrs: {
                    "active-value": "1",
                    "inactive-value": "0",
                    "active-color": "#13ce66",
                    "inactive-color": "#ccc",
                  },
                  model: {
                    value: _vm.form.flag_return,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "flag_return", $$v)
                    },
                    expression: "form.flag_return",
                  },
                }),
                _vm._v(" 默认关闭，开启后客户可在”我的“页面添加创建退货单 "),
              ],
              1
            ),
          ]),
          _c("h2", [_vm._v("营业资质")]),
          _c("el-divider"),
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              [
                _c("uploadImage", {
                  ref: "uploadImage1",
                  attrs: { limit: 1, "max-size": 3, "show-info": false },
                  on: { uploadImg: _vm.changeImg1 },
                }),
                _c("h3", { staticClass: "center" }, [_vm._v("营业执照")]),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "margin-left": "10px" } },
              [
                _c("uploadImage", {
                  ref: "uploadImage2",
                  attrs: { limit: 1, "max-size": 3, "show-info": false },
                  on: { uploadImg: _vm.changeImg2 },
                }),
                _c("h3", { staticClass: "center" }, [_vm._v("食品经营许可")]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "center" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }