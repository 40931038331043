<template>
  <!-- NAME[epic=组件] 上传多图 -->
  <div class="wrap">
    <div v-loading="loading" class="imgList" style="display: inline-block">
      <div
        v-for="(item, index) in img"
        :key="index"
        style="position: relative"
        class="imgList"
      >
        <div>
          <el-image
            style="width: 148px; height: 148px; margin-right: 10px"
            :src="item"
            fit="cover"
            :preview-src-list="img"
          ></el-image>
          <i
            v-if="!loading"
            class="el-icon-error close"
            @click="handleDeleteImg(index)"
          ></i>
        </div>
      </div>

      <el-upload
        v-show="img.length < limit"
        class="upload-demo"
        list-type="picture-card"
        multiple
        :show-file-list="false"
        :http-request="uploadImg"
        :on-exceed="limitLong"
        name="base64string"
        accept=".png, .jpg, .jpeg"
        action="#"
        :disabled="disab"
      >
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>
    <div
      v-if="showInfo"
      style="line-height: 1.2; margin-top: 10px; color: red; font-size: 12px"
    >
      {{ infoText }}
    </div>
  </div>
</template>
<script>
  import { post_ } from '@/api/Employee'

  export default {
    name: '',
    components: {},
    props: {
      limit: {
        type: Number,
        default: 5,
      },
      maxSize: {
        type: Number,
        default: 1,
      },
      showInfo: {
        type: Boolean,
        default: true,
      },
      disab: {
        type: Boolean,
        default: false,
      },
      infoText: {
        type: String,
        default:
          '（拖拽图片可进行排序，图片尺寸：800像素*800像素，图片格式： jpg、png，图片大小：1MB以内,普通展厅、一星展厅、最多上传3张图片。二星展厅及以上最多上传5张图片）',
      },
    },
    data() {
      return {
        dialogVisible: false,
        img: [],
        loading: false,
      }
    },
    computed: {},
    watch: {
      img(v) {
        if (v.length > this.limit) {
          this.$message.error(`最多上传${this.limit}张图片`)
          this.img = this.img.splice(0, this.limit)
        }
        this.$emit('getImgs', v)
      },
    },
    created() {},
    mounted() {},
    methods: {
      getBase64(file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader()
          let fileResult = ''
          reader.readAsDataURL(file)
          //开始转
          reader.onload = function () {
            fileResult = reader.result
          }
          //转 失败
          reader.onerror = function (error) {
            reject(error)
          }
          //转 结束  咱就 resolve 出去
          reader.onloadend = function () {
            resolve(fileResult)
          }
        })
      },
      uploadImg(file) {
        console.log('uploadImg', file)
        file = file.file
        if (file.size > this.maxSize * 1024 * 1000) {
          this.$message.error('图片大小不能超过' + this.maxSize + 'M')
          return false
        } else if (this.img.length > 5) {
          this.$message.error(`最多上传${this.limit}张图片`)
          return false
        } else {
          this.loading = true
          try {
            post_('/baseAdmin/common/upload-image', {
              files: file,
            }).then((e) => {
              this.img.push(e.data[0].url)
              this.$emit('uploadImg', this.img)
              this.loading = false
            })
          } catch (err) {
            this.loading = false
          }
        }
      },
      // 文件超出
      limitLong() {
        this.$message.error(`最多上传${this.limit}张图片`)
        return false
      },
      handleDeleteImg(index) {
        this.img.splice(index, 1)
        this.$emit('uploadImg', this.img)
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      onEnd(e) {
        console.log(e)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .imgList {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .close {
    position: absolute;
    top: -9px;
    right: -4px;
    font-size: 22px;
    cursor: pointer;
  }
</style>
