var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "imgList",
        staticStyle: { display: "inline-block" },
      },
      [
        _vm._l(_vm.img, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "imgList",
              staticStyle: { position: "relative" },
            },
            [
              _c(
                "div",
                [
                  _c("el-image", {
                    staticStyle: {
                      width: "148px",
                      height: "148px",
                      "margin-right": "10px",
                    },
                    attrs: {
                      src: item,
                      fit: "cover",
                      "preview-src-list": _vm.img,
                    },
                  }),
                  !_vm.loading
                    ? _c("i", {
                        staticClass: "el-icon-error close",
                        on: {
                          click: function ($event) {
                            return _vm.handleDeleteImg(index)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        }),
        _c(
          "el-upload",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.img.length < _vm.limit,
                expression: "img.length < limit",
              },
            ],
            staticClass: "upload-demo",
            attrs: {
              "list-type": "picture-card",
              multiple: "",
              "show-file-list": false,
              "http-request": _vm.uploadImg,
              "on-exceed": _vm.limitLong,
              name: "base64string",
              accept: ".png, .jpg, .jpeg",
              action: "#",
              disabled: _vm.disab,
            },
          },
          [_c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })]
        ),
      ],
      2
    ),
    _vm.showInfo
      ? _c(
          "div",
          {
            staticStyle: {
              "line-height": "1.2",
              "margin-top": "10px",
              color: "red",
              "font-size": "12px",
            },
          },
          [_vm._v(" " + _vm._s(_vm.infoText) + " ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }